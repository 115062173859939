import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga";
import HomePage from "./HomePage/HomePage";
import HeaderBar from "./components/HeaderBar/HeaderBar";
import Footer from "./components/Footer/Footer";
import { init as initAnalytics, getAnalytics } from "./clients/analytics";
import { AnalyticsMixpanel } from "./clients/analytics/AnalyticsMixpanel";
import {isMobile, isAndroid, isIOS} from "react-device-detect";
import { Layout, ConfigProvider, Alert } from "antd";
import { Language, langAbbrMap, countryCodeToLangMap } from "@hermes/schema";
import { changeLanguage } from "@hermes/shared/src/redux/user/actions";
import { useTranslation } from "./translations/hooks";
import { store } from "./clients/store";
import ReactPixel from 'react-snapchat-pixel';

import "./App.css";
import "antd/dist/antd.less";
import Helmet from "./Helmet/Helmet";
import DownloadPage from "./DownloadPage/DownloadPage";

if(isMobile){
  ReactGA.initialize("UA-123826692-4");
}
else{
  ReactGA.initialize("UA-123826692-2");
  ReactGA.set({ viewId: 228192203 });
}

const userIdentification = { user_email: '' }; // optional
const options = {
    debug: false, // enable logs
};
ReactPixel.init('d5789c3e-48d1-4c10-8664-816d07a260b0', userIdentification, options);
ReactPixel.pageView(window.location.pathname + window.location.search); 
// ReactGA.plugin.require("G-7QZ7ET7R09")
// ReactGA.set({ optimize_id: "G-7QZ7ET7R09" });
ReactGA.pageview(window.location.pathname + window.location.search);

initAnalytics(new AnalyticsMixpanel());
Sentry.init({
  dsn:
    "https://6216bdb8685a40a3980413c7203ea0ce@o345212.ingest.sentry.io/5312873",
});

const { Content } = Layout;

function App() {
  const { i18n } = useTranslation();
  const [direction, setDirection] = useState<"ltr" | "rtl" | undefined>();
  const [lang, setLang] = useState<string>();
  const [countryCode, setCountryCode] = useState('');
  const [place, setPlace] = useState<Language>(Language.English);

  const handleLangChange = (value) => {
    console.log(`selected ${value}`);
    localStorage.setItem("language", value);
    setLang(value);
    changeLang(value);
    if (value === Language.Arabic || value === Language.Urdu) {
      setDirection("rtl");
    } else {
      setDirection("ltr");
    }
  };

  const changeLang = (language) => {
    i18n.changeLanguage(langAbbrMap[language]);
    store.dispatch(changeLanguage(language as Language));
  };

  const sendGAReferralEvent = (referralTerm) => {
    ReactGA.event({
      category: "Referral",
      action: `${referralTerm}`,
    });
  };

  useEffect(() => {
    let language = localStorage.getItem("language");
    if (!language) {
      language = Language.English;
    }
    setLang(language);
    changeLang(language);
    if (language === Language.Arabic || language === Language.Urdu) {
      setDirection("rtl");
    } else {
      setDirection("ltr");
    }

  }, []);

  const getGeo = async () => {
    try {
      let country_code: string;
      return await axios
        .get(
          "https://us-central1-tenderd-firebase.cloudfunctions.net/app/utils/get_location"
        )
        .then(response => {
          country_code = response.data.country;
          return country_code;
        });
    } catch (err) {
      Sentry.captureException(err);
      console.log(err);
      return null;
    }
  };

  useEffect(() => {
    getGeo()
    .then(res => {
      //alert(JSON.stringify(res));
      if (res) {
        let countryCode = res.toUpperCase();
        //alert(countryCode);
        setCountryCode(countryCode);
      }
    })
    .catch(error => {
      Sentry.captureException(error);
    });

  }, [countryCode]);

  return (
    <ConfigProvider direction={direction}>
      <Router>
        <Layout>
          <Helmet/>
          <Route
            path="/:country"
            render={(props) => (
              <HeaderBar
                {...props}
                lang={
                  countryCodeToLangMap[countryCode]
                    ? countryCodeToLangMap[countryCode]
                    : Language.English
                }
                handleLangChange={(value) => {
                  handleLangChange(value);
                }}
              />
            )}
          />
          <Content>
            <Switch>
              <Route
                path="/"
                exact
                render={(props) => {
                  return (
                    <Redirect to={`/${countryCode.toLowerCase()}`} {...props} />
                  );
                }}
              />
              <Route
                path={`/${countryCode.toLowerCase()}`}
                //exact
                render={(props) => <HomePage
                   {...props}
                   lang={
                    window.location.pathname
                  } />}
              />
              
              {/* <Route
                path={`/${countryCode.toLowerCase()}/?`}
                exact
                render={(props) => <HomePage
                   {...props}
                   lang={
                    window.location.pathname
                  } />}
              /> */}
              <Route
                path="/ng"
                exact
                render={(props) => {
                  sendGAReferralEvent("Nigeria");
                  return (
                    <Redirect to={`/${countryCode.toLowerCase()}`} {...props} />
                  );
                }}
              />
              <Route
                path="/sa"
                exact
                render={(props) => {
                  sendGAReferralEvent("Saudi Arabia");
                  return (
                    <Redirect to={`/${countryCode.toLowerCase()}`} {...props} />
                  );
                }}
              />
              <Route
                path="/in"
                exact
                render={(props) => {
                  sendGAReferralEvent("India");
                  return (
                    <Redirect to={`/${countryCode.toLowerCase()}`} {...props} />
                  );
                }}
              />
              <Route
                path="/store/:storename"
                exact
                render={(props) => {
                  return (
                    window.location.href=`https://app.rentbook.com/${window.location.pathname}`
                  )
                }}
              />
              
              {/* <Route render={(props) => <Redirect to={`/`} {...props} />} /> */}
              <Route
                path={`/:country/*`}
                exact
                render={(props) => (
                  <Redirect to={`/${countryCode.toLowerCase()}`} {...props} />
                )}
              />

              <Route
              path="/download"
              exact
              render={(props) => <DownloadPage {...props} />}
              />
            </Switch>
          </Content>
          <Footer />
        </Layout>
      </Router>
    </ConfigProvider>
  );
}

export default App;
