import { Helmet } from "react-helmet";
import React from 'react';

const Helmet1 : React.FunctionComponent = () => {

    return (
           <Helmet>
            <meta charSet="utf-8" />
            <title>RentBook - #1 App for your Rental Business.</title>
            <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
            <meta id="english-canonical-url" content="" />
            <link rel="canonical" href="https://www.rentbook.com/"/>
            <meta name="description" content="RentBook is the #1 rental management app for your rental business. You can now manage your assets, customers and payments in one simple platform." />
            <meta name="keywords" content="Equipment rental, Bookeeping app, Accounting app, Shopify, Rental store, Online store, Bookkeeping, Accounting, Store, Rent equipments, Rental management, Manage rentals, Manage assets, Manage your customers and payments, Heavy equipments, Rent machines" />

            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="RentBook - #1 App for your Rental Business."
            />
            <meta property="og:description" content="RentBook is the #1 rental management app for your rental business. You can now manage your assets, customers and payments in one simple platform." />
            <meta property="og:url" content="https://www.rentbook.com/" />
            <meta
              property="og:site_name"
              content="RentBook - #1 App for your Rental Business."
            />
            <meta
              property="og:image"
              content="https://www.rentbook.com/assets/logo-dark.svg"
            />

            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="application-name" content="Tenderd" />
            <meta name="apple-mobile-web-app-title" content="Tenderd" />
            <meta name="theme-color" content="#ffffff" />
            <meta
              name="apple-mobile-web-app-status-bar-style"
              content="black-translucent"
            />
          </Helmet>
    );
}

export default Helmet1;
