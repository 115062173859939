import { Reference } from './base';
import { Document } from "./asset";
import { Currency } from "./user";
import { ReceiptDocument, RateTypes } from "./payment";

export enum SharedType {
    Asset = "asset",
    Receipt = "receipt",
    Rental = "rental",
    Invoice = "invoice"
}

export interface Owner{
    label?: string,
    language?: string,
    id?: string,
    phone_number?: string
}

export interface Shared {
    document?: Document[];
    gallery?: {
        image: string;
    }[];
    description?: string | number | string[] | undefined;
    name?: string | number | string[] | undefined;
    shared_at: firebase.firestore.Timestamp;
    shared_by: Reference;
    type: SharedType
}

export interface SharedAsset {
    name: string;
    description?: string;
    gallery?: {
        image: string;
    }[];
    document?: Document[];
    owner:Owner
}

export interface SharedReceipt{
    currency: Currency;
    people_ref?: Reference;
    asset_ref?: Reference;
    rate: number,
    rate_type: RateTypes,
    duration: number,
    amount: number;
    receipt_date: firebase.firestore.Timestamp;
    documents?: ReceiptDocument[], 
    description?: string,
}

export interface SharedInvoice{
    amount: number;
    people_ref?: Reference;
    invoice_date: firebase.firestore.Timestamp;
    asset_ref?: Reference;
    rate: number,
    currency: Currency;
    rate_type: RateTypes,
    duration: number,
    description?: string,
}