import { QuerySnapshot, QueryDocumentSnapshot } from "./types";
import { FirestoreDocument, Collection, SharedAsset, Shared, SharedReceipt, SharedInvoice } from "@hermes/schema";

export class SharedQuery {

    constructor(private store: firebase.firestore.Firestore) {

    }

    get(id: string) {
        return this.store
        .collection(Collection.Shared)
        .doc(id)
        .get().then((d) => {
            return {
                id: d.id,
                doc: d.data() as Shared,
            } as FirestoreDocument<Shared>;
        });
    }

    getSharedAsset(id: string) {
        return this.store
        .collection(Collection.Shared)
        .doc(id)
        .collection(Collection.SharedAsset)
        .get() as Promise<QuerySnapshot<SharedAsset>>;
    }

    getSharedReceipt(id: string) {
        return this.store
        .collection(Collection.Shared)
        .doc(id)
        .collection(Collection.SharedReceipt)
        .get() as Promise<QuerySnapshot<SharedReceipt>>;
    }

    getSharedInvoice(id: string) {
        return this.store
        .collection(Collection.Shared)
        .doc(id)
        .collection(Collection.SharedInvoice)
        .get() as Promise<QuerySnapshot<SharedInvoice>>;
    }

    create(shared: Shared) {
        return this.store
            .collection(Collection.Shared)
            .add(shared)
    }

    createSharedAsset(sharedId: string, asset: SharedAsset) {
        return this.store
            .collection(Collection.Shared)
            .doc(sharedId)
            .collection(Collection.SharedAsset)
            .add(asset)
    }

    createSharedReceipt(sharedId: string, receipt: SharedReceipt) {
        return this.store
            .collection(Collection.Shared)
            .doc(sharedId)
            .collection(Collection.SharedReceipt)
            .add(receipt)
    }

    createSharedInvoice(sharedId: string, invoice: SharedInvoice) {
        return this.store
            .collection(Collection.Shared)
            .doc(sharedId)
            .collection(Collection.SharedInvoice)
            .add(invoice)
    }

}