
import { Analytics } from './Analytics';

let qObject: Analytics;

export const init = (q: Analytics) => {
    qObject = q;
}

export enum Events {
    SignUP = "SignUP",
    ClickHowItWorks = 'ClickHowItWorks',
    ClickContactUs= 'ClickContactUs',
    ClickGetStarted='ClickGetStarted',
    ClickChangeLanguage = "ClickChangeLanguage",
    ClickDownloadPlaystore = 'ClickDownloadPlaystore',
    ClickDownloadAppstore  = 'ClickDownloadAppstore'
}

export const getAnalytics = () => qObject;