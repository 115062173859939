import * as actions from './actions';
import { ActionType } from 'typesafe-actions';
import { ActionTypes } from './constants';
import * as firebase from '@firebase/testing';

import { FirestoreDocument, Payment } from '@hermes/schema';

export interface IState {
    [id: string]: FirestoreDocument<Payment>,
}

export type Actions = ActionType<typeof actions>;

export const initialState: IState = {

}

export default function (state = initialState, action: Actions): IState {
    switch (action.type) {
        case ActionTypes.GET_PAYMENTS_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case ActionTypes.GET_PAYMENT_SUCCESS:
            return {
                ...state,
                ...action.payload,
            }
        case ActionTypes.ADD_PAYMENT_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        case ActionTypes.UPDATE_PAYMENT_SUCCESS:
            const updated = {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    doc: {
                        ...state[action.payload.id].doc,
                        ...action.payload.doc,
                    }
                }
            };
            return updated;
        case ActionTypes.DELETE_PAYMENT_SUCCESS:
            delete state[action.payload];
            return {
                ...state,
            };
         case ActionTypes.GET_ASSET_PAYMENT_SUCCESS:
            return {
                ...state,
                ...action.payload,
            }
    }
    return state;
}