import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//imported components within webapp
import "./GetStartedModal.css";
import { getAnalytics, Events } from "../../clients/analytics";
import { useTranslation } from "../../translations/hooks";
//shared folder imports
import {
  updatePeople,
  loadPeople,
  addPeople,
} from "@hermes/shared/src/redux/people/actions";
import {
  People,
  FirestoreDocument,
  countriesArray,
  countryCodes,
} from "@hermes/schema";
//antd imports
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Select,
  notification,
  Alert,
} from "antd";
//other library imports
import PhoneInput from "react-phone-input-2";
import { StoreState } from "@hermes/shared/src/redux/store/types";
import GetStartedComponent from "../GetStartedComponent/GetStartedComponent";

interface ModalProps {
  visible: boolean;
  closeModal: () => void;
}

const { Option } = Select;

const GetStartedModal: React.FunctionComponent<ModalProps> = ({
  visible,
  closeModal,
}) => {
  // const user = useSelector((state: StoreState) => state.user).user;
  const { i18n, t } = useTranslation();
  const [contactName, setContactName] = useState<string>();
  const [error, setError] = useState({ key: "", value: false });
  const [phone_number, setPhone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const displayErrorMsg = () => {
    
  };

  const handleFormatPhone = () => {};

  const handleOk = async () => {};

  const closeError = () => {
  };

  useEffect(() => {
    
  }, []);

  return (
    <>
      <Modal
        closable={false}
        className="get-started-modal"
        visible={visible}
        onOk={handleOk}
        onCancel={closeModal}
        okButtonProps={{ loading: loading }}
        footer={null}
      >
        <GetStartedComponent />
      </Modal>
    </>
  );
};

export default GetStartedModal;
