import * as actions from './actions';
import { ActionType } from 'typesafe-actions';
import { ActionTypes } from './constants';
import { FirestoreDocument, People } from '@hermes/schema';

export interface IState {
    [id: string]: FirestoreDocument<People>;
}

export type Actions = ActionType<typeof actions>;

export const initialState: IState = {
}

export default function (state = initialState, action: Actions): IState {
    switch (action.type) {
        case ActionTypes.GET_PEOPLE_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case ActionTypes.ADD_PEOPLE_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case ActionTypes.DELETE_PEOPLE_SUCCESS:
            delete state[action.payload];
            return {
                ...state,
            };
        case ActionTypes.UPDATE_PEOPLE_SUCCESS:
            const updated = {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    doc: {
                        ...state[action.payload.id].doc,
                        ...action.payload.doc,
                    }
                }
            };
            return updated;
    }
    return state;
}