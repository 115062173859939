import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//imported components within webapp
import { getService } from '../../clients';
import { getAnalytics, Events } from "../../clients/analytics";
import { useTranslation } from "../../translations/hooks";
//shared folder imports
import {
  updatePeople,
  loadPeople,
  addPeople,
} from "@hermes/shared/src/redux/people/actions";
import {
  People,
  FirestoreDocument,
  countriesArray,
  countryCodes,
} from "@hermes/schema";
//antd imports
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Select,
  notification,
  Alert,
  Button
} from "antd";
//other library imports
import PhoneInput from "react-phone-input-2";
import { StoreState } from "@hermes/shared/src/redux/store/types";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import GetStartedModal from "../GetStartedModal/GetStartedModal";
import "./GetStartedComponent.css";
import { getServer } from "../../clients/server";
import { HomePageTKeys } from "../../translations/keys";
import { getSentry } from "../../clients";
import ReactGA from "react-ga";
import ReactPixel from 'react-snapchat-pixel';

interface ModalProps {
  visible: boolean;
  closeModal: () => void;
}

const appLink = "https://app.rentbook.com";
const playstoreLink =
  "https://play.google.com/store/apps/details?id=com.tenderd.rentbook";
const appstoreLink = "https://apps.apple.com/us/app/id1520161034";

const { Option } = Select;

const GetStartedComponent: React.FunctionComponent<{defaultCountry?: string}> = ({defaultCountry}) => {
  const { i18n, t } = useTranslation();

  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const checkIfNumberExistsInLead = async (num: string) => {
    const leadData = await getService().leadQuery.getLeadByPhone(num);
    console.log(leadData.docs.length, "checking lead")
    if (leadData.docs.length === 0) {
      ReactGA.event({category: `Lead Collected: ${num}`, action: `WebApp`});
    }
    getServer()
    .getStarted({ phone: phone })
    .then((result) => {
        setTimeout(() => {
          window.location.href = `${appLink}/login/${phone}`;
        }, 1000);
    })
    .catch((err) => {
        getSentry().captureException(err.message);
        console.log(err);
    });
  }

  return (
    <div className="get-started-component">
      <Row justify="start" gutter={[0, 5]}>
        <Col xs={14} lg={14}>
          <span className="enterNumMsg">
            {i18n.t(HomePageTKeys.enterNumMsg)}
          </span>
        </Col>
        <Col xs={14} lg={14}>
          <PhoneInput
            country={defaultCountry? defaultCountry: "ae"}
            searchPlaceholder="Enter your phone number"
            alwaysDefaultMask={true}
            preferredCountries={["ae", "sa", "ng", "id", "my"]}
            inputProps={{
              name: "phone",
              direction: "ltr",
            }}
            value={phone}
            onChange={(e) => {
              setPhone(`+${e}`);
            }}
          />
        </Col>
        <Col xs={10} lg={8}>
          <Button
            className="get-started-butt"
            type="primary"
            block
            loading={loading}
            size="large"
            onClick={() => {
              setLoading(true);
              const phoneNumber = parsePhoneNumberFromString(phone);
              if (phoneNumber && phoneNumber.isValid() === true) {
                  checkIfNumberExistsInLead(phone)
                  getAnalytics().track(Events.ClickGetStarted, {
                    phone_number: phoneNumber,
                  });
                  window.location.href = `${appLink}/login/${phone}`;
                  ReactGA.event({category: `Lead Collected: ${phone}`, action: `WebApp`});
                  ReactPixel.track(`Lead Collected: ${phone}`);
                //Redirect to RentBook WebApp
              } else {
                let config = {
                  message: i18n.t(HomePageTKeys.invalidHeading),
                  description: i18n.t(HomePageTKeys.invalidDesc),
                };
                notification.error(config);
                setLoading(false);
              }
            }}
          >
            {i18n.t(HomePageTKeys.getStartedButton)}
          </Button>
        </Col>
      </Row>
      {/* <Row justify="space-around" gutter={[{ xs: 0, sm: 10 }, 0]}>
        <Col xs={11} sm={12} lg={12}>
          <a href={playstoreLink}>
            <motion.img
              whileHover={{ scale: 1.03, opacity: 0.5 }}
              src="/assets/playstore.svg"
            />
          </a>
        </Col>
        <Col xs={11} sm={12} lg={12}>
          <a href={appstoreLink}>
            <motion.img
              whileHover={{ scale: 1.03, opacity: 0.5 }}
              src="/assets/appstore.svg"
            />
          </a>
        </Col>
      </Row> */}
    </div>
  );
};
export default GetStartedComponent;
