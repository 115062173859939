import { Query } from "../query/Query";
import { Language, Currency, MobilePlatform } from "@hermes/schema";
import { FirebaseUtils } from "..";

export interface RentbookUserForm {
    id: string, 
    fields: { name: string | "", currency?: Currency, phone_number: string, language: Language, email?: string | null, country?: string | null}, 
    extra: {
        pushToken?: string,
        pushNotification?: boolean,
        platform?: MobilePlatform,
        providerId?: string,
    }
}

export class AuthService {

    constructor(private app: firebase.app.App, private query: Query, private fb: FirebaseUtils) {}

    createNewUser(form: RentbookUserForm) {
        return this.query.createUser({
            id: form.id,
            doc: {
                ...form.fields,
                internal: {
                    push_notification: form.extra.pushNotification? form.extra.pushNotification: true,
                    push_token: form.extra.pushToken? form.extra.pushToken: '',
                    created_at: this.fb.fromDate(new Date()),
                    updated_at: this.fb.fromDate(new Date()),
                    platform: form.extra.platform? form.extra.platform: MobilePlatform.Android,
                    providerId: form.extra.providerId? form.extra.providerId: ''
                },
            }
        })
    }

    

}