import { Collection, FirestoreDocument, Payment, PaymentType } from "@hermes/schema";
import { QueryDocumentSnapshot, QuerySnapshot } from "./types";

export class PaymentQuery {

    constructor(private store: firebase.firestore.Firestore) {}

    create(userId: string, doc: Payment) {
        return this.store
            .collection(Collection.User)
            .doc(userId)
            .collection(Collection.Payment)
            .add(doc)
    }

    get(userId: string, paymentId: string) {
        return this.store
        .collection(Collection.User)
        .doc(userId)
        .collection(Collection.Payment)
        .doc(paymentId)
        .get() as Promise<QueryDocumentSnapshot<Payment>>;
    }

    getAll(userId: string) {
        return this.store
        .collection(Collection.User)
        .doc(userId)
        .collection(Collection.Payment)
        .get() as Promise<QuerySnapshot<Payment>>;
    }

    update(userId: string, payment: FirestoreDocument<Partial<Payment>>) {
        return this.store
            .collection("users")
            .doc(userId)
            .collection('payments')
            .doc(payment.id)
            .update(payment.doc);
    }

    getAssetPayments(userId: string, assetId: string) {
        return this.store
            .collection(Collection.User)
            .doc(userId)
            .collection(Collection.Payment)
            .where("asset_ref.id", "==", assetId)
            .get() as Promise<QuerySnapshot<Payment>>;
    }

    getContactPayments(userId: string, peopleId: string) {
        return this.store
            .collection(Collection.User)
            .doc(userId)
            .collection(Collection.Payment)
            .where("people_ref.id", "==", peopleId)
            .get() as Promise<QuerySnapshot<Payment>>;
    }

    delete(userId: string, paymentId: string) {
        return this.store
            .collection("users")
            .doc(userId)
            .collection('payments')
            .doc(paymentId)
            .delete();
    }

    getPaymentsByEndDateRange(start_date: firebase.firestore.Timestamp, end_date: firebase.firestore.Timestamp) {
        return this.store
        .collectionGroup(Collection.Payment)
        .orderBy("data.end_date", "desc")
        .startAt(end_date)
        .endAt(start_date)
        .get() as Promise<QuerySnapshot<Payment>>;
    }

    getIncomePaymentsByEndDateRange(start_date: firebase.firestore.Timestamp, end_date: firebase.firestore.Timestamp) {
        return this.store
        .collectionGroup(Collection.Payment)
        .orderBy("data.end_date", "desc")
        .where("type", "==", PaymentType.Income)
        .startAt(end_date)
        .endAt(start_date)
        .get() as Promise<QuerySnapshot<Payment>>;
    }

    getElapsedEndDates(today: firebase.firestore.Timestamp, assetId: string) {
        return this.store
        .collectionGroup(Collection.Payment)
        .where('ended', "==", false)
        .where("asset_ref.id", "==", assetId)
        .orderBy("data.end_date", "desc")
        .startAt(today)
        //.where("data.end_date", "<=", today)
        .get() as Promise<QuerySnapshot<Payment>>;
    }

    getOngoingPaymentDates(today: firebase.firestore.Timestamp, assetId: string) {
        return this.store
        .collectionGroup(Collection.Payment)
        .where("asset_ref.id", "==", assetId)
        .orderBy("data.end_date", "desc")
        .endAt(today)
        //.where("data.end_date", ">=", today)
        .get() as Promise<QuerySnapshot<Payment>>
    }

    getAllOngoingPayments() {
        return this.store
        .collectionGroup(Collection.Payment)
        .where('ended', "==", false)
        .get() as Promise<QuerySnapshot<Payment>>
    }
    

    getElapsedPayments(today: firebase.firestore.Timestamp) {
        return this.store
        .collectionGroup(Collection.Payment)
        .where("data.on_going", "==", true)
        .orderBy("data.end_date", "desc")
        .startAt(today)
        .get() as Promise<QuerySnapshot<Payment>>;   
    }

    getEndedPayments(today: firebase.firestore.Timestamp) {
        return this.store
        .collectionGroup(Collection.Payment)
        .where("ended", "==", true)
        .orderBy("data.end_date")
        .endAt(today)
        .get() as Promise<QuerySnapshot<Payment>>;  
    }

    getIncomePaymentsByDate(end_date: firebase.firestore.Timestamp) {
        return this.store
        .collectionGroup(Collection.Payment)
        .where("type", "==", PaymentType.Income)
        .orderBy("data.end_date", "desc")
        .endAt(end_date)
        .get() as Promise<QuerySnapshot<Payment>>;
    }

    getAllByCollectionGroup() {
        return this.store
            .collectionGroup(Collection.Payment)
            .get().then((doc) => {
                return doc.docs.map((d) => {
                    return {
                        id: d.id,
                        doc: d.data()
                    } as FirestoreDocument<Payment>;
                })
            });
    }
    
}