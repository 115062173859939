import { ABTest, Collection, FirestoreDocument } from "@hermes/schema";
import { QueryDocumentSnapshot, QuerySnapshot } from "./types";

export class AbTestQuery {

    constructor(private store: firebase.firestore.Firestore) {}

    // create(doc: ABTest) {
    //     return this.store
    //         .collection(Collection.ABTest)
    //         .add({
    //             ...doc,
    //             internal: {
    //                 created_at: new Date(),
    //             }
    //         })
    // }

    create(doc: FirestoreDocument<Partial<ABTest>>) {
        return this.store.collection(Collection.ABTest).doc(doc.id).set(doc.doc, {merge: true})
    }

    update(id: string, user: FirestoreDocument<Partial<ABTest>>) {
        return this.store
            .collection(Collection.ABTest)
            .doc(id)
            .update(user.doc);
    }

    get(id: string) {
        return this.store
        .collection(Collection.ABTest)
        .doc(id)
        .get().then(snapshot => {
            const assetDoc: FirestoreDocument<ABTest> = {
                id: snapshot.id,
                doc: snapshot.data() as ABTest,
            };
            return assetDoc;
        }); 
    }
    
    getAll() {
        return this.store
        .collection(Collection.ABTest)
        .get().then((doc) => {
            return doc.docs.map((d) => {
                return {
                    id: d.id,
                    doc: d.data()
                } as FirestoreDocument<ABTest>;
            })
        });
    }
    
}