import { QuerySnapshot, QueryDocumentSnapshot, SnapshotQuery } from "./types";
import { FirestoreDocument, Assets, Collection, Status } from "@hermes/schema";

export interface UserData {
    id: string,
    phone_number: string
}

export enum FileType {
    IMAGE_PNG = 'image/png',
    IMAGE_JPG = 'image/jpeg',
    PDF = 'application/pdf'
}

export interface Document {
    name?: string
    url: string
    type: FileType
    expiry_date?: firebase.firestore.Timestamp
}




export class AssetQuery {

    constructor(private store: firebase.firestore.Firestore) {

    }

    getAssetByCreatedDate(today: firebase.firestore.Timestamp) {
        return this.store
        .collectionGroup(Collection.Asset)
        .orderBy("internal.created_at")
        .endAt(today)
        .get() as Promise<QuerySnapshot<Assets>>;
    }

    getAsset(userId: string, assetId: string) {
        return this.store
        .collection(Collection.User)
        .doc(userId)
        .collection(Collection.Asset)
        .doc(assetId)
        .get()
        .then(snapshot => {
            const assetDoc: FirestoreDocument<Assets> = {
                id: snapshot.id,
                doc: snapshot.data() as Assets,
            };
            return assetDoc;
        });    
    };

    create(userId: string, asset: Assets) {
        return this.store
            .collection("users")
            .doc(userId)
            .collection('assets')
            .add(asset)
    }

    update(userId: string, asset: FirestoreDocument<Partial<Assets>>) {
        return this.store
            .collection("users")
            .doc(userId)
            .collection('assets')
            .doc(asset.id)
            .update(asset.doc);
    }

    getAll(userId: string) {
        return this.store
            .collection("users")
            .doc(userId)
            .collection('assets')
           .get() as Promise<QuerySnapshot<Assets>>;
    }

    getAllTest(userId: string) {
        return this.store
            .collection("users")
            .doc(userId)
            .collection("assets")
            .onSnapshot((snap) => {
                return snap as QuerySnapshot<Assets>;
            });
    }

    get(userId: string, assetId: string) {
        return this.store
        .collection(Collection.User)
        .doc(userId)
        .collection(Collection.Asset)
        .doc(assetId)
        .get() as Promise<QueryDocumentSnapshot<Assets>>;
    }


    delete(userId: string, assetId: string) {
        return this.store
            .collection("users")
            .doc(userId)
            .collection('assets')
            .doc(assetId)
            .delete();
    }

    getAssetsByStatus(status: Status) {
        return this.store
            .collectionGroup(Collection.Asset)
            .where("status", "==", status)
            .get() as Promise<QuerySnapshot<Assets>>
    }

    getAllByCollectionGroup() {
        return this.store
            .collectionGroup(Collection.Asset)
            .get().then((doc) => {
                return doc.docs.map((d) => {
                    return {
                        id: d.id,
                        doc: d.data()
                    } as FirestoreDocument<Assets>;
                })
            });
    }

}