import { Analytics, UserProps, TrackProps } from './Analytics';
import mixpanel from "mixpanel-browser";



export class AnalyticsMixpanel implements Analytics {
    
    constructor() {
        mixpanel.init("88fcd6293ed6752ff4c96228133d188d");
    }

    setUserId(id: string) {
        try {
            mixpanel.identify(id);
        } catch(err) {
            console.log(err)
        }
    }

    setUserProps(props: UserProps) {
        try {
            mixpanel.people.set({
                "$name": props.name, 
                "$phone_number": props.phone_number, 
            });
        } catch(err) {
            console.log(err)
        }
    }

    setUserAttribute(name: string, value: string) {
        try {
            mixpanel.people.set({
                [name]: value,
            });
        } catch(err) {
            console.log(err)
        }
    }

    track(name: string, props: TrackProps) {
        try {
            mixpanel.track(name, props)
        } catch(err) {
            console.log(err)
        }
    }

}