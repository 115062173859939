
import { Assets, FirestoreDocument, PaymentType } from '@hermes/schema';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { ActionTypes } from './constants';

export interface IState {
    activeAsset?: string;
    activePayment?: string,
    activeContact?: string;
    activePaymentType?: string,
    newAssetId?: string,
    newContactId?: string,
    isNewPayment?: boolean,
    isNewAsset?: boolean,
    network?: {
        isConnected: boolean,
        isReachable: boolean | null | undefined
    },
    nameSet?: boolean,
}

export type Actions = ActionType<typeof actions>;

export const initialState: IState = {
}

export default function(state = initialState, action: Actions): IState {
    switch (action.type) {
        case ActionTypes.SET_ACTIVE_ASSET: 
        return {
            ...state,
            activeAsset: action.payload,
        };
        case ActionTypes.SET_ACTIVE_PAYMENT: 
        return {
            ...state,
            activePayment: action.payload,
        };
        case ActionTypes.SET_ACTIVE_CONTACT: 
        return {
            ...state,
            activeContact: action.payload,
        };
        case ActionTypes.SET_ACTIVE_TYPE:
        return {
            ...state,
            activePaymentType: action.payload,
        };
        case ActionTypes.SET_ASSET_METADATA:
        return {
            ...state,
            newAssetId: action.payload,
        };
        case ActionTypes.SET_CONTACT_METADATA:
        return {
            ...state,
            newContactId: action.payload,
        };
        case ActionTypes.SET_IS_NEW_PAYMENT:
        return {
            ...state,
            isNewPayment: action.payload
        }
        case ActionTypes.SET_IS_NEW_ASSET:
            return {
                ...state,
                isNewAsset: action.payload
            }
        case ActionTypes.UPDATE_NETWORK_STATE:
        return {
            ...state,
            network: action.payload
        }
        case ActionTypes.IS_NAME_SET:
        return {
            ...state,
            nameSet: action.payload
        }
    }
    return state;
}