
export class UploadService {

    constructor(private client: firebase.app.App) {}
  
    async upload(uri: string, ref: firebase.storage.Reference) {
      const name = uri.substr(uri.lastIndexOf("/")+1);
      const response = await fetch(uri);
      const blob = await response.blob();
      let imageRef = ref.child(name);
      return imageRef.put(blob).then((snap) => {
        return snap.ref.getDownloadURL();
      });
    }
  }
  