// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum CommonTKeys {
	sampleText = 'Common.sampleText',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum FooterTKeys {
	copyright = 'Footer.copyright',
	email = 'Footer.email',
	getInTouch = 'Footer.getInTouch',
	whatsapp = 'Footer.whatsapp',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum HeaderTKeys {
	ContactUs = 'Header.ContactUs',
	GetStarted = 'Header.GetStarted',
	howItWorks = 'Header.howItWorks',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum HomePageTKeys {
	catalogueHeading = 'HomePage.catalogueHeading',
	cataloguePara = 'HomePage.cataloguePara',
	downloadHeading = 'HomePage.downloadHeading',
	downloadPara = 'HomePage.downloadPara',
	easyRentalHeading = 'HomePage.easyRentalHeading',
	easyRentalPara = 'HomePage.easyRentalPara',
	enterNumMsg = 'HomePage.enterNumMsg',
	getStartedButton = 'HomePage.getStartedButton',
	invalidDesc = 'HomePage.invalidDesc',
	invalidHeading = 'HomePage.invalidHeading',
	manageAssets = 'HomePage.manageAssets',
	manageBooks = 'HomePage.manageBooks',
	manageScheduleHeading = 'HomePage.manageScheduleHeading',
	manageSchedulePara = 'HomePage.manageSchedulePara',
	multiLangHeading = 'HomePage.multiLangHeading',
	multiLangPara = 'HomePage.multiLangPara',
	newCustomersHeading = 'HomePage.newCustomersHeading',
	newCustomersPara = 'HomePage.newCustomersPara',
	number1App = 'HomePage.number1App',
	oweOthers = 'HomePage.oweOthers',
	remindersHeading = 'HomePage.remindersHeading',
	remindersPara = 'HomePage.remindersPara',
	startUsingButton = 'HomePage.startUsingButton',
	trackRental = 'HomePage.trackRental',
}

