import { FirestoreDocument, Collection, InquiryNotification } from "@hermes/schema";

export class InquiryNotificationQuery {

    constructor(private store: firebase.firestore.Firestore) {

    }

    get(id: string) {
        return this.store
        .collection(Collection.InquiryNotification)
        .doc(id)
        .get().then((d) => {
            return {
                id: d.id,
                doc: d.data() as InquiryNotification,
            } as FirestoreDocument<InquiryNotification>;
        });
    }

    create(inquiry: InquiryNotification) {
        return this.store
            .collection(Collection.InquiryNotification)
            .add(inquiry)
    }

    getAll(userId: string) {
        return this.store
        .collection(Collection.InquiryNotification)
        .where("user_ref.id", "==", userId)
        .orderBy('internal.created_at', "desc")
        .get().then((doc) => {
            return doc.docs.map((d) => {
                return {
                    id: d.id,
                    doc: d.data()
                } as FirestoreDocument<InquiryNotification>;
            })
        });
    }

    update(inquiryId: string, doc: FirestoreDocument<Partial<InquiryNotification>>) {
        return this.store
        .collection(Collection.InquiryNotification)
        .doc(inquiryId)
        .update(doc.doc)
    }

}