import * as firebase from 'firebase';

export enum Language {
    English = "english",
    Hindi = "hindi",
    Arabic = "arabic",
    Urdu = "urdu",
    Indonesian = "indonesian"
}

export enum Currency{
  AED = "AED",
  SAR = "SAR",
  INR = "INR",
  NGN = "NGN",
  IDR = "IDR",
  MYR = "MYR",
  PHP = "PHP",
  CAD = "CAD",
}

export const localeLookup: {[lang: string]: Language} = {
  "ar_AE": Language.Arabic,
  "en_US": Language.English,
  "hi": Language.Hindi,
  "id": Language.Indonesian,
  "ur": Language.Urdu,
  "en": Language.English
}

export const langSymbolMap: {[l in Language]: string} = {
  [Language.English]: "english",
  [Language.Arabic]: "اردو",
  [Language.Hindi]: "हिन्दी",
  [Language.Urdu]: "عربى",
  [Language.Indonesian]: "indonesian"
};

export interface ExpoPushToken {
  type: 'expo';
  data: string;
}

export const languageVideo: { [l in Language]: string } = {
  [Language.Arabic]: 'rW9-N3AjVJ0',
  [Language.English]: 'PEWZnqG07JQ',
  [Language.Hindi]: 'PEWZnqG07JQ',
  [Language.Urdu]: 'PEWZnqG07JQ',
  [Language.Indonesian]: 'HcshvSf0DE8',
}

export const langAbbrMap: { [l in Language]: string } = {
    [Language.Arabic]: 'ar',
    [Language.English]: 'en',
    [Language.Hindi]: 'hi',
    [Language.Urdu]: 'ur',
    [Language.Indonesian]: 'id',
}
export const langCodeArray = ["ar", "en", "hi", "ur", "id"];
export const countryCodeToLangMap: {[c: string]: Language} = {
  "AE": Language.English,
  "ID": Language.Indonesian,
  "IN": Language.English,
  "MY": Language.English,
  "NG": Language.English,
  "CA": Language.English,
  "PH": Language.English,
  "SA": Language.Arabic,
};

export const langToIndex: {[l in Language]: number} = {
  [Language.English]: 0,
  [Language.Hindi]: 1,
  [Language.Arabic]: 2,
  [Language.Urdu]: 3,
  [Language.Indonesian]: 4
};

export const langIndexMap: { [idx: number]: {abbr: string, full: Language, lower: string, id: string, label: string,}} = {
    0: {
      abbr: "en",
      full: Language.English,
      lower: "english",
      id: "En",
      label: "English",
    },
    1: {
      abbr: "hi",
      full: Language.Hindi,
      lower: "hindi",
      id: "हिन्दी",
      label: "हिन्दी"
    },
    2: {
      abbr: "ar",
      full: Language.Arabic,
      lower: "arabic",
      id: "اردو",
      label: "اردو"
    } ,
    3: {
      abbr: "ur",
      full: Language.Urdu,
      lower: "urdu",
      id: "عربى",
      label: "عربى"
    },
    4: {
      abbr: "id",
      full: Language.Indonesian,
      lower: "indonesian",
      id: "In",
      label: "Bahasa Indonesia"
    }
  }

export const languagesArray = [
  {
    key: "english",
    label: "English"
  },
  {
    key: "arabic",
    label: "Arabic"
  },
  {
    key: "urdu",
    label: "Urdu"
  },
  {
    key: "hindi",
    label: "Hindi"
  },
  {
    key: "indonesian",
    label: "Bahasa Indonesia"
  }
];

export const CurrencyMap: {[name: string]: Currency} = {
  "inr": Currency.INR,
  "aed": Currency.AED,
  "sar": Currency.SAR,
  "ngn": Currency.NGN,
  "idr": Currency.IDR,
  "php": Currency.PHP,
  "myr": Currency.MYR,
  "cad": Currency.CAD,
}

export const currencyDefaultCode: {[phone: string]: string} = {
  "+234": "ng",
  "+971": "ae",
  "+966": "sa",
  "+91": "inr",
  "+63": "ph",
  "+60": "my",
  "+62": "id"
};

export const currenciesArray = [
  { label: Currency.AED, value: Currency.AED },
  { label: Currency.SAR, value: Currency.SAR },
  { label: Currency.INR, value: Currency.INR },
  { label: Currency.NGN, value: Currency.NGN },
  { label: Currency.IDR, value: Currency.IDR },
  { label: Currency.MYR, value: Currency.MYR },
  { label: Currency.PHP, value: Currency.PHP },
  { label: Currency.CAD, value: Currency.CAD },

];

export const countryCodes: {[c in Currency]: string} = {
  [Currency.AED]: "+971",
  [Currency.IDR]: "+62",
  [Currency.INR]: "+91",
  [Currency.MYR]: "+60",
  [Currency.NGN]: "+234",
  [Currency.PHP]: "+63",
  [Currency.SAR]: "+966",
  [Currency.CAD]: "+1",
};



export const countriesArray = ["ng","sa","ae","id", "ca"];

export const currencyToCountryMap: {[c in Currency]: string} = {
  [Currency.AED]: "UAE",
  [Currency.IDR]: "Indonesia",
  [Currency.INR]: "India",
  [Currency.MYR]: "Malaysia",
  [Currency.NGN]: "Nigeria",
  [Currency.PHP]: "Phillipines",
  [Currency.SAR]: "KSA",
  [Currency.CAD]: "Canada"
};


//list of users who indicated uninterest in RB
// export const listOfUnsubscribedPhoneNumbers = [
//   {
//     name: "Anita Devi",
//     email: "anitasenani76@gmail.com",
//     phone: "+919450433874"
//   }
// ];

export const listOfUnsubscribedPhoneNumbers = ["+919450433874"];


export const phonePrefixToCountryMap: {[s: string]: string} = {
  "+234": currencyToCountryMap[Currency.NGN],
  "+62": currencyToCountryMap[Currency.IDR],
  "+91": currencyToCountryMap[Currency.INR],
  "+60": currencyToCountryMap[Currency.MYR],
  "+63": currencyToCountryMap[Currency.PHP],
  "+966": currencyToCountryMap[Currency.SAR],
  "+1": currencyToCountryMap[Currency.CAD],
  "+971": currencyToCountryMap[Currency.AED],
};

export enum MobilePlatform {
  Android = "android",
  IOS = "ios",
}


//Onboarding level
//0 - lowest level of onboarding (name, email, phone, currency)
//1 - 
export interface User {
    name: string;
    phone_number: string;
    language: Language;
    currency: Currency;
    email?: string,
    country?: string,
    store_name?: string,
    internal: {
        onboarding?: {
          level: number
        },
        ip?: string,
        //onboarded?: true,
        verified_number?: boolean,
        providerId?: string,
        login_count?: number,
        onboarding_screen?: boolean,
        location?: string,
        created_at: firebase.firestore.Timestamp,
        updated_at: firebase.firestore.Timestamp,
        push_token?: string,
        push_notification?: boolean,
        last_opened_next_7_days?: firebase.firestore.Timestamp, //to keep track of the last time the last opened date sms was sent
        last_opened?: firebase.firestore.Timestamp,
        last_opened_sms?: boolean,
        last_sent_sms?: boolean, //if user has received sms of the "first two days"
        last_sent_sms_date?: firebase.firestore.Timestamp, //keep track of the last time no asset/rental sms was sent
        next_7_days?: firebase.firestore.Timestamp,  //next 7 days of no asset/rental
        platform?: MobilePlatform,
        has_shared_asset?: boolean, //if the user has shared asset before (or used the share feature at least once)
        next_7_days_share_asset_notification?: firebase.firestore.Timestamp, //next 7 days of not shared asset yet
        notified_after_2_days?: boolean //if user has received notification of the first two days
    },
    sound?: boolean,
}

export interface ABTest {
  conversions: number,
  visited: number,
  screenName: "manage_rentals" | "get_inquiries" | "login_direct",
 // platform: MobilePlatform,
  //uniqueId: string,
  internal?: {
    updated_at?: firebase.firestore.Timestamp,
    created_at?: firebase.firestore.Timestamp,
  },
}