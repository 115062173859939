// export enum RequestKeys {
//     GET_ASSETS = "GET_ASSETS",
// }

export enum ActionTypes {
    GET_SHARED_REQUEST = 'GET_SHARED_REQUEST',
    GET_SHARED_SUCCESS = "GET_SHARED_SUCCESS",
    GET_SHARED_FAILURE = "GET_SHARED_FAILURE",
}

