import { createStore, combineReducers, applyMiddleware } from 'redux';
import userReducer from '../user/reducer';
import { ActionTypes } from '../user/constants';
import { loadingReducer, errorReducer } from '../apiState/reducer';
import assetsReducer from '../assets/reducer';
import paymentsReducer from '../payments/reducer';
import peopleReducer from '../people/reducer';
import shareReducer from '../shared/reducer';
import shareAssetReducer from '../sharedAsset/reducer';
import shareReceiptReducer from '../sharedReceipt/reducer';
import shareInvoiceReducer from '../sharedInvoice/reducer';
import uiReducer from '../ui/reducer';

const appReducer = combineReducers({
    user: userReducer,
    api: combineReducers({
        loading: loadingReducer,
        error: errorReducer,
    }),
    assets: assetsReducer,
    payments: paymentsReducer,
    people: peopleReducer,
    ui: uiReducer,
    share: shareReducer, 
    shareAsset: shareAssetReducer,
    shareReceipt: shareReceiptReducer,
    shareInvoice: shareInvoiceReducer
});

export default (state, action) => {
    // if (action.type === ActionTypes.LOGOUT) {
    //   console.log("clear state");
    //   state = undefined
    // }
    return appReducer(state, action)
  }