import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import { composeWithDevTools } from 'remote-redux-devtools';

import root_reducer from './root_reducer';
import { Service, Client } from '../../business';


// TODO: use devTools based on environment!
const composeEnhancers = composeWithDevTools({
  realtime: true,
  name: 'Tenderd',
})


export const create = (service: Service, client: Client) => {
  return createStore(root_reducer, composeEnhancers(
    applyMiddleware(thunk.withExtraArgument({
        client,
        service
    }))
  ));
}
