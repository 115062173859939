
import { Assets, FirestoreDocument } from '@hermes/schema';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { ActionTypes } from './constants';


export interface IState {
    [id: string]: FirestoreDocument<Assets>;
}

export type Actions = ActionType<typeof actions>;

export const initialState: IState = {
}

export default function(state = initialState, action: Actions): IState {
    switch (action.type) {
        case ActionTypes.GET_ASSETS_SUCCESS: 
        return {
            ...state,
            ...action.payload,
        };
        case ActionTypes.GET_ASSET_SUCCESS:
        return {
            ...state,
            ...action.payload,
        }
        case ActionTypes.ADD_ASSET_SUCCESS: 
        return {
            ...state,
            ...action.payload,
        };
        case ActionTypes.DELETE_ASSET_SUCCESS:
            delete state[action.payload];
            return {
                ...state,
            };
        case ActionTypes.UPDATE_ASSET_SUCCESS:
            console.log(state[action.payload.id].doc, "->", action.payload.doc)
                const updated = {
                    ...state,
                    [action.payload.id]: {
                        ...state[action.payload.id],
                        doc: {
                            ...state[action.payload.id].doc,
                            ...action.payload.doc,
                        }
                    }
                };
                console.log("updated", updated[action.payload.id])
                return updated;
    }
    return state;
}