import React, { useState, useEffect } from "react";

import { Layout, Menu, Button, Select, ConfigProvider } from "antd";
import GetStartedModal from "../GetStartedModal/GetStartedModal";
import "./HeaderBar.css";
import { useTranslation } from "../../translations/hooks";
import { HeaderTKeys } from "../../translations/keys";
import { getAnalytics, Events } from "../../clients/analytics";
import ReactGA from "react-ga";
import { langAbbrMap, Language, languagesArray } from "@hermes/schema";

const { Header } = Layout;

const appLink = "https://app.rentbook.com";

type Props = {
  handleLangChange(lang: string): void;
  lang: Language
}


const HeaderBar: React.FunctionComponent<Props> = (props) => {
  const { i18n } = useTranslation();

  const [showGetStartedModal, setShowGetStartedModal] = useState(false);

  const [language, setLanguage] = useState<Language>(props.lang);
  // const [direction, setDirection] = useState<'ltr' | 'rtl' | undefined>();

  const goToSection = (elementId) => {
    let section = document.getElementById(elementId);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
      });
    }
  };


  const handleLangChange = (value) => {
    setLanguage(value)
    props.handleLangChange(value)
  }

  useEffect(() => {
    setLanguage(props.lang);
    i18n.changeLanguage(langAbbrMap[props.lang]);   
  },[props.lang])

  return (
    <Header className={"app-header"}>
      <GetStartedModal
        visible={showGetStartedModal}
        closeModal={() => {
          setShowGetStartedModal(false);
        }}
      />
      <div className="container">
        <div className="logo">
          <img src="/assets/logo-dark.svg" />
        </div>
        <Menu mode="horizontal">
          <Menu.Item
            className="hide-mobile"
            key="1"
            onClick={() => {
              goToSection("how-it-works");
              getAnalytics().track(Events.ClickHowItWorks, {});
              ReactGA.event({
                category: "Clicked How It Works",
                action: `Clicked How It Works`,
              });
            }}
          >
            {i18n.t(HeaderTKeys.howItWorks)}
          </Menu.Item>
          <Menu.Item
            className="hide-mobile"
            key="2"
            onClick={() => {
              goToSection("contact-us");
              getAnalytics().track(Events.ClickContactUs, {});
              ReactGA.event({
                category: "Clicked Contact Us",
                action: `Clicked Contact Us`,
              });
            }}
          >
            {i18n.t(HeaderTKeys.ContactUs)}
          </Menu.Item>
          <Menu.Item key="3" className="hide-mobile">
            <Button
              type="primary"
              size="large"
              href={appLink}
              onClick={() => {
                // setShowGetStartedModal(true);
                getAnalytics().track(Events.ClickGetStarted, {});
                ReactGA.event({
                  category: "Clicked Get Started(Header)",
                  action: `Get Started(Header)`,
                });
              }}
            >
              {i18n.t(HeaderTKeys.GetStarted)}
            </Button>
          </Menu.Item>
          <Menu.Item key="4">
            <Select
              value={language}
              onChange={(e) => {
                handleLangChange(e);
                getAnalytics().track(Events.ClickChangeLanguage, {
                  language: e,
                });
                ReactGA.event({
                  category: "Language Changed",
                  action: `Language changed to ${e}`,
                });
              }}
            >
              {languagesArray.map((v) => {
                  return (
                      <Select.Option value={v.key}>{v.label}</Select.Option>
                  )
              })}
            </Select>
          </Menu.Item>
        </Menu>
      </div>
    </Header>
  );
};

export default HeaderBar;
