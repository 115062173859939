export enum RequestKeys {
    GET_PEOPLE = "GET_PEOPLE",
}

export enum ActionTypes {
    GET_PEOPLE_REQUEST = "GET_PEOPLE_REQUEST",
    GET_PEOPLE_SUCCESS = "GET_PEOPLE_SUCCESS",
    GET_PEOPLE_FAILURE = "GET_PEOPLE_FAILURE",
    ADD_PEOPLE_SUCCESS = 'ADD_PEOPLE_SUCCESS',
    ADD_PEOPLE_FAILURE = 'ADD_PEOPLE_FAILURE',
    ADD_PEOPLE_REQUEST = 'ADD_PEOPLE_REQUEST',

    UPDATE_PEOPLE_SUCCESS = "UPDATE_PEOPLE_SUCCESS",

    DELETE_PEOPLE_SUCCESS = "DELETE_PEOPLE_SUCCESS",
}
