export enum RequestKeys {
    GET_PAYMENTS = "GET_PAYMENTS",
    GET_PAYMENT = "GET_PAYMENT",
}

export enum ActionTypes {
    GET_PAYMENTS_REQUEST = "GET_PAYMENTS_REQUEST",
    GET_PAYMENT_REQUEST = "GET_PAYMENT_REQUEST",
    GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS",
    GET_PAYMENT_SUCCESS = "GET_PAYMENT_SUCCESS",
    GET_PAYMENTS_FAILURE = "GET_PAYMENTS_FAILURE",
    GET_PAYMENT_FAILURE = "GET_PAYMENT_FAILURE",
    ADD_PAYMENT_SUCCESS = 'ADD_PAYMENT_SUCCESS',
    ADD_PAYMENT_FAILURE = 'ADD_PAYMENT_FAILURE',
    ADD_PAYMENT_REQUEST = 'ADD_PAYMENT_REQUEST',
    ASSET_PAYMENT_REQUEST = 'ASSET_PAYMENT_REQUEST',
    GET_ASSET_PAYMENT_FAILURE = 'GET_ASSET_PAYMENT_FAILURE',
    GET_ASSET_PAYMENT_SUCCESS = 'GET_ASSET_PAYMENT_SUCCESS',

    UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS",

    DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS",
}

