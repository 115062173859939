
import { Shared, FirestoreDocument, SharedReceipt } from '@hermes/schema';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { ActionTypes } from './constants';


export interface IState {
    [id: string]: FirestoreDocument<SharedReceipt>;
}

export type Actions = ActionType<typeof actions>;

export const initialState: IState = {
}

export default function(state = initialState, action: Actions): IState {
    switch (action.type) {
        case ActionTypes.GET_SHARED_RECEIPT_SUCCESS:
        return {
            ...state,
            ...action.payload,
        }
    }
    return state;
}