import { action } from "typesafe-actions";
import { ActionTypes } from "./constants";
import { delay } from 'bluebird';
import { ThunkAction } from "redux-thunk";
import { StoreState } from "../store/types";
import { FirestoreDocument, User, Language } from '@hermes/schema';
import { Service } from "../../business";
import { AnyAction } from "redux";

export const login = (user: FirestoreDocument<User>) => action(ActionTypes.LOGIN, user);
export const clearState = () => action(ActionTypes.CLEAR_STATE);
export const updateUserSuccess = (user: Partial<User>) => action(ActionTypes.UPDATE_USER, user);
export const changeLanguage = (l: Language) => action(ActionTypes.CHANGE_LANGUAGE, l);


// sample thunk action
export const doLogin = (): ThunkAction<Promise<void>, StoreState, {}, AnyAction> => {
    return (dispatch, getState) => {
        return Promise.resolve();
    };
};

export const updateUser = (id: string, doc: Partial<User>): ThunkAction<Promise<void>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        const user = getState().user.user;
        return service.query.updateUser(user.id, { id, doc }).then((res) => {
            //dispatch(updateUserSuccess( doc ));
            dispatch(updateUserSuccess({...doc }));
        }).catch((err) => {
            throw err;
        });
    };
};

