import { Query } from "@hermes/shared/src/business/query/Query";
import { Service, Client, FirebaseUtils } from "@hermes/shared/src/business/index";
import { getFirebaseWebsite } from "./firebase";
import { AssetQuery } from "@hermes/shared/src/business/query/AssetQuery";
import { PaymentQuery } from "@hermes/shared/src/business/query/PaymentQuery";
import { PeopleQuery } from "@hermes/shared/src/business/query/PeopleQuery";
import { AuthService } from "@hermes/shared/src/business/auth/AuthService";
import { SharedQuery } from "@hermes/shared/src/business/query/SharedQuery";
import { UploadService } from "@hermes/shared/src/business/functions";
import { LeadQuery } from "@hermes/shared/src/business/query/LeadQuery";
import { AbTestQuery } from "@hermes/shared/src/business/query/AbTestQuery";
import * as Sentry from '@sentry/react';
import * as firebase from 'firebase';
import { InquiryNotificationQuery } from "@hermes/shared/src/business/query/InquiryNotificationQuery";

const app = getFirebaseWebsite();

const rbFirebase: FirebaseUtils = {
    fromDate: firebase.firestore.Timestamp.fromDate,
}

const query = new Query(app.firestore());
const authService = new AuthService(app, query, rbFirebase);
const uploadService = new UploadService(app);
const assetQuery = new AssetQuery(app.firestore());
const paymentQuery = new PaymentQuery(app.firestore());
const peopleQuery = new PeopleQuery(app.firestore());
const sharedQuery = new SharedQuery(app.firestore());
const abtestQuery = new AbTestQuery(app.firestore());
const leadQuery = new LeadQuery(app.firestore());
const inquiryNotificationQuery = new InquiryNotificationQuery(app.firestore());

const service: Service = {
    abtestQuery: abtestQuery,
    query,
    sharedQuery,
    authService,
    assetQuery,
    inquiryNotificationQuery,
    paymentQuery,
    peopleQuery,
    leadQuery,
    firebase: rbFirebase,
    uploadService
}

const client: Client = {
    fbApp: app
}

export const getService = () => service;
export const getClient = () => client;
export const getSentry = () => Sentry;