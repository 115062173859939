import i18next from 'i18next';
import Common from './pages/Common.json';
import HomePage from './pages/HomePage.json';
import Header from './pages/Header.json';
import Footer from './pages/Footer.json'
import { initReactI18next } from "react-i18next";

const languages = ['en', 'ar', 'hi', 'ur', 'id'];
const languageMap: { [lang: string]: { [page: string]: { [key: string]: string }} } = {};
languages.forEach((lang) => {
    languageMap[lang] = {};
});
const pages: { page: string, translations: any }[] = [
    {
        page: 'Common',
        translations: Common,
    },
    {
        page: 'HomePage',
        translations: HomePage,
    },
    {
        page: 'Header',
        translations: Header,
    },
    {
        page: 'Footer',
        translations: Footer,
    }
];

pages.forEach((page) => {
    languages.forEach((lang) => {
        languageMap[lang][page.page] = {};
    });
    Object.keys(page.translations).forEach((key) => {
        languages.forEach((lang) => {
            if (page.translations[key][lang]) {
                languageMap[lang][page.page][key] = page.translations[key][lang] || "";
            } else {
                languageMap[lang][page.page][key] = page.translations[key]['en'] || "";
            }
        });
    });
});

const newInstance = i18next.createInstance();

newInstance
.use(initReactI18next)
.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',
    defaultNS: "common",                             // language to use
    resources: {
        en: {
            common: languageMap['en'],               // 'common' is our custom namespace
        },
        ar: {
            common: languageMap['ar'],
        },
        hi: {
            common: languageMap['hi'],
        },
        ur: {
            common: languageMap['ur'],
        },
        id: {
            common: languageMap['id'],
        },
    },
});

export const i18n = newInstance;