import * as firebase from 'firebase';
import { Reference } from './base';
import { Document } from './asset';


export enum PaymentType {
    Income = "income",
    Expense = "expense",
}

export enum RateTypes {
    One_time = "One time",
    Monthly = "Monthly",
    Yearly = "Yearly",
    Daily = "Daily",
    Weekly = "Weekly",
}

export type ReceiptDocument = Omit<Document, 'expiry_date'>;

export interface Receipt{
    description?: string,
    documents: ReceiptDocument[],
    amount: number,
    created_at: firebase.firestore.Timestamp
}
export interface PaymentTypeData {
    description?: string,
    rate: number,
    rate_type: RateTypes,
    duration: number,
    start_date: firebase.firestore.Timestamp,
    end_date?: firebase.firestore.Timestamp,
    on_going: boolean,
    receipts?: Receipt[]
}

export interface Payment {
    user_ref: Reference,
    asset_ref?: Reference,
    people_ref?: Reference,
    type: PaymentType,
    data: PaymentTypeData,
    internal: {
        created_at: firebase.firestore.Timestamp,
        updated_at: firebase.firestore.Timestamp,
        passed_payment_reminded?: boolean,
    },
    ended?: boolean,
    recurred_dates?: firebase.firestore.Timestamp[],
}