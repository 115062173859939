
export enum ActionTypes {
    SET_ACTIVE_ASSET = 'SET_ACTIVE_ASSET',
    SET_ACTIVE_CONTACT = 'SET_ACTIVE_CONTACT',
    SET_ACTIVE_PAYMENT = 'SET_ACTIVE_PAYMENT',
    SET_ACTIVE_TYPE = 'SET_ACTIVE_TYPE',

    SET_ASSET_METADATA = 'SET_ASSET_METADATA',
    SET_CONTACT_METADATA = 'SET_CONTACT_METADATA',
    SET_IS_NEW_PAYMENT = 'SET_IS_NEW_PAYMENT',

    UPDATE_NETWORK_STATE = 'UPDATE_NETWORK_STATE',

    IS_NAME_SET = 'IS_NAME_SET',
    SET_INITIAL_ROUTE = 'SET_INITIAL_ROUTE',
    SET_IS_NEW_ASSET = "SET_IS_NEW_ASSET"
}

