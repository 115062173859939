import { IServerClient, GetStartedForm, ShareAssetForm, ShareAssetResponse, SendSmsForm, ShareReceiptsForm, ShareInvoicesForm, PushNotificationForm, SendLoginOTPForm, LoginWithOTPForm, SignUPWithOTPForm, UpdateUserForm, OnUpdateAssetForm, CreateInquiryNotificationForm } from ".";


// export const DefaultEndPoint = 'https://us-central1-hermes-5a3c3.cloudfunctions.net/app';
export const DefaultEndPoint = "https://api.rentbook.com"

export class ServerError extends Error {
    constructor(public message: string, public status: number) {
      super(message + ' Code: ' + status)
    }
}

export class ServerClient implements IServerClient {
    
    constructor(
      private app?: firebase.app.App,
      private endPoint = DefaultEndPoint
    ) {}

    // Custom JSON stringify to handle firebase dates
    stringify(val: any) {
        return JSON.stringify(val, (key, value) => {
            if (value && typeof value.toDate === "function") {
                return value.toDate().toJSON();
            }
            return value;
        });
    }
  
    async getToken(): Promise<string> {
      const user = this.app.auth().currentUser
      if (user) {
        const token = await user.getIdToken()
        return token
      }
      throw new Error('user not logged in.')
    }

    fetchBase(url: string, params: RequestInit) {
        console.log(`${this.endPoint}${url}`, params);
        return fetch(`${this.endPoint}${url}`, params).then(res => {
            return res.json().then(body => {
            if (res.status >= 400) {
                throw new ServerError((body && body.error) || 'Something went wrong.', res.status)
            }
            return body
            })
        });
    }

    async fetchWithToken(url: string, params: RequestInit) {
        const token = await this.getToken();
        params.headers = {
            ...(params.headers || {}),
            Authorization: 'Bearer ' + token,
        }
        return this.fetchBase(url, params);
    }

    async fetch(url: string, params: RequestInit) {
        params.headers = {
            ...(params.headers || {}),
            'Content-Type': 'application/json',
        }
        return this.fetchWithToken(url, params);
    }

    async fetchWithoutToken(url: string, params: RequestInit) {
        params.headers = {
            ...(params.headers || {}),
            'Content-Type': 'application/json',
        }
        return this.fetchBase(url, params);
    }
  

    getStarted(form: GetStartedForm) {
        return this.fetchWithoutToken(`/rentbook/utils/get_started`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(form),
        })
    }

    sendLoginOTP(form: SendLoginOTPForm) {
        return this.fetchWithoutToken(`/rentbook/auth/send_login_otp`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(form),
        })
    }


    getInquiryNotifications(userId: string) {
        return this.fetchWithoutToken(`/rentbook/utils/get_inquiries`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify({userId: userId}),
        })
    }

    updateInquiryNotification(inquiryId: string) {
        return this.fetchWithoutToken(`/rentbook/utils/update_inquiry`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify({inquiryId: inquiryId}),
        })
    }


    createInquiryNotification(form: CreateInquiryNotificationForm) {
        return this.fetchWithoutToken(`/rentbook/utils/create_inquiry_notification`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(form),
        })
    }

    updateUserPhone(form: UpdateUserForm) {
        return this.fetchWithoutToken(`/rentbook/auth/update_user_phone`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(form),
        })
    }

    loginWithOTP(form: LoginWithOTPForm) {
        return this.fetchWithoutToken(`/rentbook/auth/login_with_otp`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(form),
        })
    }

    checkIfPhoneExists(phone: string) {
        return this.fetchWithoutToken(`/rentbook/auth/check_phone_exists`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify({phone: phone}),
        })
    }

    sendCSVExport(userId: string) {
        return this.fetch(`/rentbook/assets/send_csv_export`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify({userId: userId}),
        })
    }

    onUpdateAsset(form: OnUpdateAssetForm) {
        return this.fetch(`/rentbook/assets/update_asset`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(form),
        })
    }

    getUserByStoreName(name: string) {
        return this.fetchWithoutToken(`/rentbook/utils/get_user_by_store_name`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify({name: name}),
        })
    }

    getUserAssets(userId: string) {
        return this.fetchWithoutToken(`/rentbook/utils/get_user_assets`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify({userId: userId}),
        })
    }

    checkIfEmailExists(email: string) {
        return this.fetchWithoutToken(`/rentbook/auth/check_email_exists`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify({email: email}),
        })
    }

    sendSignUpOTP(form: SendLoginOTPForm) {
        return this.fetchWithoutToken(`/rentbook/auth/send_signup_otp`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(form),
        })
    }

    signUpWithOTP(form: SignUPWithOTPForm) {
        return this.fetchWithoutToken(`/rentbook/auth/signup_with_otp`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(form),
        })
    }


    shareAsset(form: ShareAssetForm): Promise<ShareAssetResponse> {
        return this.fetch(`/rentbook/assets/share`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(form),
        })
    }

    shareReceipt(form: ShareReceiptsForm): Promise<ShareAssetResponse> {
        return this.fetch(`/rentbook/receipts/share`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(form),
        })
    }

    shareInvoice(form: ShareInvoicesForm): Promise<ShareAssetResponse> {
        return this.fetch(`/rentbook/invoices/share`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(form),
        })
    }

    sendSms(form: SendSmsForm) {
        return this.fetchWithoutToken(`/rentbook/utils/send_sms`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(form),
        })
    }

    // sendPushNotificaton(form: PushNotificationForm) {
    //     return this.fetch(`/rentbook/utils/sendPushNotifications`, {
    //         mode: 'cors',
    //         method: 'POST',
    //         body: JSON.stringify(form),
    //     })
    // }

}