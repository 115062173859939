export enum RequestKeys {
    GET_ASSETS = "GET_ASSETS",
}

export enum ActionTypes {
    GET_ASSET_REQUEST = 'GET_ASSET_REQUEST',
    GET_ASSET_SUCCESS = "GET_ASSET_SUCCESS",
    GET_ASSETS_REQUEST = "GET_ASSETS_REQUEST",
    GET_ASSETS_SUCCESS = "GET_ASSETS_SUCCESS",
    GET_ASSETS_FAILURE = "GET_ASSETS_FAILURE",

    ADD_ASSET_REQUEST = "ADD_ASSET_REQUEST",
    ADD_ASSET_SUCCESS = "ADD_ASSET_SUCCESS",
    ADD_ASSET_FAILURE = "ADD_ASSET_FAILURE",
    
    UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS",

    DELETE_ASSET_SUCCESS = "DELETE_ASSET_SUCCESS",
}

