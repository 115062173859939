import { Collection, FirestoreDocument, Lead } from "@hermes/schema";
import { QuerySnapshot } from "./types";

export class LeadQuery {

    constructor(private store: firebase.firestore.Firestore) {}

    create(doc: Lead, providerId?: string) {
        return this.store
            .collection(Collection.Lead)
            .add({
                ...doc,
                internal: {
                    created_at: new Date(),
                    providerId: providerId? providerId: ''
                }
            })
    }

    getLeadByPhone(phone: string) {
        return this.store
        .collection(Collection.Lead)
        .where("phone", "==", phone)
        .get() as Promise<QuerySnapshot<Lead>>;
    }

    getLeadByEmail(email: string) {
        return this.store
        .collection(Collection.Lead)
        .where("email", "==", email)
        .get() as Promise<QuerySnapshot<Lead>>;
    }

    // getAll() {
    //     return this.store
    //     .collection(Collection.Lead)
    //     .get().then((docs) => {
    //         return docs.docs.map((d) => {
    //             const doc: FirestoreDocument<Lead> = { id: d.id, doc: d.data() as Lead };
    //             return doc;
    //         })
    //     })
    // }
    getAll() {
        return this.store
        .collection(Collection.Lead)
        .get() as Promise<QuerySnapshot<Lead>>;
    }
    
}