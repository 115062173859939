import * as React from 'react';
import { useState, useEffect } from 'react';
import { Input, Select, Radio, Button, Alert, Row, Col, Card } from "antd";
import {
  Link,
} from "react-router-dom";
import {isMobile, isAndroid, isIOS} from "react-device-detect";
import "./DownloadPage.css";
import ReactGA from "react-ga";

const playstoreLink =
  "https://play.google.com/store/apps/details?id=com.tenderd.rentbook";
const appstoreLink = "https://apps.apple.com/us/app/id1520161034";



const DownloadPage: React.FunctionComponent<{}> = ({}) => {
  
    useEffect(() => {
        if (isAndroid) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.tenderd.rentbook"
        } else if (isIOS) {
            window.location.href = "https://apps.apple.com/us/app/id1520161034"
        }
        else {
            window.location.href = "https://rentbook.com/" 
        }
    }, []);
  return (
    <div className="download-page">
      <Row justify="center" className="app-badges">
        <Col span={14}>
          <img className="bg" src="/assets/logo-dark.svg" />
        </Col>
        <Col span={20}>
          <img className="bg" src="/assets/download.svg" />
        </Col>
        <Col span={18}>
          <a href={playstoreLink}>
            <img src="/assets/playstore.svg" />
          </a>
        </Col>
        <Col span={18}>
          <a href={appstoreLink}>
            <img src="/assets/appstore.svg" />
          </a>
        </Col>
        <Col span={20}>
          <h3>
            Download the RentBook App from the Playstore or Appstore to get
            started!
          </h3>
        </Col>
      </Row>
    </div>
  );
};

export default DownloadPage;