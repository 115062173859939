import * as React from "react";
import { useEffect, useState } from "react";

import { Layout, Row, Col, Tag } from "antd";
import {
  FacebookFilled,
  TwitterOutlined,
  LinkedinFilled,
  InstagramOutlined,
  MailOutlined, 
  WhatsAppOutlined
} from "@ant-design/icons";

import { useTranslation } from "../../translations/hooks";
import { FooterTKeys } from "../../translations/keys";
import "./Footer.css";

const Footer: React.FunctionComponent = () => {
  const { i18n } = useTranslation();

  const goToSocial = (url:string) => {
    window.open(url)
  };

  return (
    <Layout.Footer className={"app-footer"} id="contact-us">
      <Row className="container" justify="space-between">
        <Col span={8} className="logo">
          <img src="/assets/logo-dark.svg" />
          <p>{i18n.t(FooterTKeys.copyright)}</p>
          <div className="footer-icon-social">
                <TwitterOutlined
                  name="twitter"
                  style={{ color: "#1DA1F2" }}
                  onClick={() => {
                    goToSocial("https://twitter.com/rentbookglobal");
                  }}
                />
                <FacebookFilled
                  name="facebook"
                  style={{ color: "#3B5998" }}
                  onClick={() => {
                    goToSocial("https://www.facebook.com/rentbookglobal");
                  }}
                />
                <LinkedinFilled
                  name="linkedin"
                  style={{ color: "#0077B5" }}
                  onClick={() => {
                    goToSocial("https://www.linkedin.com/company/rentbookglobal");
                  }}
                />
                <InstagramOutlined
                  name="instagram"
                  style={{ color: "RGBA(223, 92, 78, 1.00)" }}
                  onClick={() => {
                    goToSocial("https://instagram.com/rentbookglobal");
                  }}
                />
              </div>
        </Col>
        <Col span={16} className="content">
          <h3>{i18n.t(FooterTKeys.getInTouch)}</h3>
          <p>
            <span>
              <MailOutlined />
              {i18n.t(FooterTKeys.email)}{" : "}
            </span>
            <Tag color="orange">support@rentbook.com</Tag>
          </p>
          <p>
            <span>
              <WhatsAppOutlined />
              {i18n.t(FooterTKeys.whatsapp)}{" : "}
            </span>
            <Tag color="orange">+971 50 594 0900 </Tag>{" "}
            <Tag color="orange">+91 989 541 7777</Tag>
          </p>
        </Col>
      </Row>
    </Layout.Footer>
  );
};

export default Footer;
