import * as firebase from 'firebase';
import { Reference } from './base';

export enum FileType {
    IMAGE_PNG = 'image/png',
    IMAGE_JPG = 'image/jpeg',
    PDF = 'application/pdf'
}

export enum Status {
    RENTED_OUT = 'rented out',
    IDLE= 'idle'
}

export interface Document {
    name?: string
    url: string
    type: FileType
    expiry_date?: firebase.firestore.Timestamp,
    tags?: string[],
}

export enum AssetCategory {
    EquipmentRental = 'equipment_rental',
    Others = 'others',
}
 
export interface Assets {
    user_ref: Reference,
    name: string;
    description?: string;
    identifier?: string;
    gallery?: {
        image: string,
        tags?: string[],
    }[]
    status: Status,
    document?: Document[]
    internal: {
        category?: AssetCategory,
        created_at: firebase.firestore.Timestamp,
        updated_at: firebase.firestore.Timestamp,
    }
}