import * as React from "react";
import { useEffect, useState } from "react";
import { Row, Col, Button, Card, Carousel, Avatar, notification, Tag } from "antd";
import PhoneInput from "react-phone-input-2";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import "./HomePage.css";
import GetStartedComponent from "../components/GetStartedComponent/GetStartedComponent";
import { changeLanguage } from "@hermes/shared/src/redux/user/actions";
import { getServer } from "../clients/server";
import { useTranslation } from "../translations/hooks";
import { HomePageTKeys } from "../translations/keys";
import { getAnalytics, Events } from "../clients/analytics";
import { getSentry } from "../clients";
import ReactGA from "react-ga";
import MetaTags from 'react-meta-tags';
import { countryCodeToLangMap, langAbbrMap, Language } from "@hermes/schema";

const appLink = "https://app.rentbook.com";
const playstoreLink =
  "https://play.google.com/store/apps/details?id=com.tenderd.rentbook";
const appstoreLink = "https://apps.apple.com/us/app/id1520161034";

interface Props {
  lang: Language,
  match: {
    params: {
      country: string;
    };
  };
}



const HomePage: React.FunctionComponent<Props> = (props) => {
  //Animation States
  const [testimonialRef, testimonialInView] = useInView({
    threshold: 0.5,
    triggerOnce: true
  });
  const [usecase1InViewRef, usecase1InView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  const [usecase2InViewRef, usecase2InView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  const [usecase3InViewRef, usecase3InView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  const [getStartedRef2, getStartedRef2InView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  const {i18n, t} = useTranslation();
  const pathName = window.location.pathname;
  const defaultCountry = pathName.split("/")[1];
  const lang = countryCodeToLangMap[pathName.split("/")[1].toUpperCase()]? countryCodeToLangMap[pathName.split("/")[1].toUpperCase()]: Language.English;
  const [language, setLanguage] = useState<Language>(lang);


  useEffect(() => {
    setLanguage(lang);
    i18n.changeLanguage(langAbbrMap[lang]);
  }, [language]);

  return (
    <div className="home-page">
      {/* SEO Section */}
      <MetaTags>
            <title>RentBook - #1 App for your Rental Business.</title>
            <meta name="description" content="RentBook is the #1 rental management app for your rental business. You can now manage your assets, customers and payments in one simple platform." />
            <meta property="og:title" content="RentBook" />
            <meta name="author" content="Tenderd" />
            <meta name="keywords" content="Equipment rental, Bookeeping app, Accounting app, Shopify, Rental store, Online store, Bookkeeping, Accounting, Store, Rent equipments, Rental management, Manage rentals, Manage assets, Manage your customers and payments, Heavy equipments, Rent machines" />
      </MetaTags>

      {/* Get Started Section */}
      <motion.img
        initial={{ x: "50%", opacity: 0 }}
        animate={{ x: "0", opacity: 1 }}
        transition={{ duration: 3 }}
        className="bg"
        src="/assets/bg.svg"
      />
      <div className="section get-started">
        <Row justify="space-between" align="middle">
          <Col xs={24} md={12} lg={12} xl={10}>
            <motion.div
              initial={{ x: "-50%", opacity: 0 }}
              animate={{ x: "0", opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <h1 style={{textAlign:'center'}}>{i18n.t(HomePageTKeys.number1App)}</h1>
              <p style={{textAlign:'center'}}>{i18n.t(HomePageTKeys.manageAssets)}.</p>
              <GetStartedComponent defaultCountry={defaultCountry} />
            </motion.div>
          </Col>
          <Col xs={24} md={10} lg={12} xl={10}>
            <motion.img
              initial={{ y: "-50%", opacity: 0 }}
              animate={{ y: "0", opacity: 1 }}
              transition={{ duration: 1 }}
              className="phone-mockup"
              src="/assets/phone-rental-list.png"
            />
          </Col>
        </Row>
      </div>

      {/* User testimonial section 1 */}
      <Row className="section full-width testimonial_1">
        <img className="bg" src="/assets/testimonial-bg.jpg" />
        <Row className="section" justify="end" align="bottom">
          <Col xs={24} md={12}>
            <motion.div
              ref={testimonialRef}
              animate={
                testimonialInView
                  ? { x: "0", opacity: 1 }
                  : { x: "50%", opacity: 0 }
              }
              transition={{ duration: 1 }}
            >
              <Card>
                <h2>{i18n.t(HomePageTKeys.newCustomersHeading)}</h2>
                <p>{i18n.t(HomePageTKeys.newCustomersPara)}</p>
                <Button
                  type="link"
                  size="large"
                  href={appLink}
                  onClick={() => {
                    getAnalytics().track(Events.ClickGetStarted, {});
                    ReactGA.event({
                      category: "Clicked Get Started",
                      action: `Clicked Get Started`,
                    });
                  }}
                >
                  {i18n.t(HomePageTKeys.startUsingButton)}
                </Button>
              </Card>
            </motion.div>
          </Col>
        </Row>
      </Row>

      {/* User Screens and Explanations as below */}
      <Row
        className="section use-cases"
        justify="space-around"
        align="middle"
        id="how-it-works"
      >
        <Col xs={24} md={10} lg={10} xl={10}>
          <motion.div
            ref={usecase1InViewRef}
            animate={
              usecase1InView
                ? { y: "0", opacity: 1 }
                : { y: "50px", opacity: 0 }
            }
            transition={{ duration: 1 }}
          >
            <h2>{i18n.t(HomePageTKeys.catalogueHeading)}</h2>
            <p>{i18n.t(HomePageTKeys.cataloguePara)}</p>
          </motion.div>
          <motion.div
            animate={
              usecase1InView
                ? { x: "0", opacity: 1 }
                : { x: "-50px", opacity: 0 }
            }
            transition={{ duration: 2 }}
          >
            <Card className="feature-card">
              <h3>
                <Avatar src="/assets/lang.svg" />
                <b>{i18n.t(HomePageTKeys.multiLangHeading)}</b>
              </h3>
              <p>{i18n.t(HomePageTKeys.multiLangPara)}</p>
              <Tag color="orange">English</Tag>
              <Tag color="orange">हिन्दी | Hindi</Tag>
              <Tag color="orange">عربى | Arabic</Tag>
              <Tag color="orange">اردو | Urdu</Tag>
            </Card>
          </motion.div>
        </Col>
        <Col xs={24} md={10} lg={10} xl={10}>
          <motion.div
            className="phone-circle"
            animate={
              usecase1InView ? { x: "0", opacity: 1 } : { x: "50%", opacity: 0 }
            }
            transition={{ duration: 1 }}
          >
            <motion.img
              className="phone-mockup"
              src="/assets/phone-asset-list.png"
              animate={
                usecase1InView
                  ? { y: "0", opacity: 1 }
                  : { y: "50%", opacity: 0 }
              }
              transition={{ duration: 1.5 }}
            />
          </motion.div>
        </Col>
      </Row>

      <Row
        className="section use-cases reverse"
        justify="space-around"
        align="middle"
      >
        <Col xs={24} md={10} lg={10} xl={10}>
          <motion.div
            ref={usecase2InViewRef}
            animate={
              usecase2InView
                ? { y: "0", opacity: 1 }
                : { y: "50px", opacity: 0 }
            }
            transition={{ duration: 1 }}
          >
            <h2>{i18n.t(HomePageTKeys.manageScheduleHeading)}</h2>
            <p>{i18n.t(HomePageTKeys.manageSchedulePara)}</p>
          </motion.div>
        </Col>
        <Col xs={24} md={10} lg={10} xl={10}>
          <motion.div
            className="phone-circle"
            animate={
              usecase2InView
                ? { x: "0", opacity: 1 }
                : { x: "-50%", opacity: 0 }
            }
            transition={{ duration: 1 }}
          >
            <motion.img
              className="phone-mockup"
              src="/assets/phone-rental-list.png"
              animate={
                usecase2InView
                  ? { y: "0", opacity: 1 }
                  : { y: "50%", opacity: 0 }
              }
              transition={{ duration: 1.5 }}
            />
          </motion.div>
        </Col>
      </Row>

      <Row className="section use-cases" justify="space-around" align="middle">
        <Col xs={24} md={10} lg={10} xl={10}>
          <motion.div
            ref={usecase3InViewRef}
            animate={
              usecase3InView
                ? { y: "0", opacity: 1 }
                : { y: "50px", opacity: 0 }
            }
            transition={{ duration: 1 }}
          >
            <h2>{i18n.t(HomePageTKeys.easyRentalHeading)}</h2>
            <p>{i18n.t(HomePageTKeys.easyRentalPara)}</p>
          </motion.div>
          <motion.div
            animate={
              usecase3InView
                ? { x: "0", opacity: 1 }
                : { x: "-50px", opacity: 0 }
            }
            transition={{ duration: 2 }}
          >
            <Card className="feature-card">
              <h3>
                <Avatar src="/assets/alert.svg" />
                <b>{i18n.t(HomePageTKeys.remindersHeading)}</b>
              </h3>
              <p>{i18n.t(HomePageTKeys.remindersPara)}</p>
              <Tag color="orange">SMS</Tag>
              <Tag color="orange">WhatsApp</Tag>
            </Card>
          </motion.div>
        </Col>
        <Col xs={24} md={10} lg={10} xl={10}>
          <motion.div
            className="phone-circle"
            animate={
              usecase3InView ? { x: "0", opacity: 1 } : { x: "50%", opacity: 0 }
            }
            transition={{ duration: 1 }}
          >
            <motion.img
              className="phone-mockup"
              src="/assets/phone-rental-detail.png"
              animate={
                usecase3InView
                  ? { y: "0", opacity: 1 }
                  : { y: "50%", opacity: 0 }
              }
              transition={{ duration: 1.5 }}
            />
          </motion.div>
        </Col>
      </Row>

      {/* User testimonial 2 section 1 */}
      <div className="section full-width testimonial_2">
        <Carousel autoplay>
          <div className="slide-container">
            <Card>
              <Row gutter={[{ xs: 0, sm: 50 }, 0]}>
                <Col xs={24} md={12}>
                  <img src="/assets/t1.jpg" />
                </Col>
                <Col xs={24} md={12}>
                  <h2>{i18n.t(HomePageTKeys.trackRental)}</h2>
                  <Button
                    type="link"
                    size="large"
                    href={appLink}
                    onClick={() => {
                      getAnalytics().track(Events.ClickGetStarted, {});
                      ReactGA.event({
                        category: "Clicked Get Started",
                        action: `Clicked Get Started`,
                      });
                    }}
                  >
                    {i18n.t(HomePageTKeys.startUsingButton)}
                  </Button>
                </Col>
              </Row>
            </Card>
          </div>
          <div className="slide-container">
            <Card>
              <Row gutter={[{ xs: 0, sm: 50 }, 0]}>
                <Col xs={24} md={12}>
                  <img src="/assets/t2.jpg" />
                </Col>
                <Col xs={24} md={12}>
                  <h2>{i18n.t(HomePageTKeys.manageBooks)}</h2>
                  <Button
                    type="link"
                    size="large"
                    href={appLink}
                    onClick={() => {
                      getAnalytics().track(Events.ClickGetStarted, {});
                      ReactGA.event({
                        category: "Clicked Get Started",
                        action: `Clicked Get Started`,
                      });
                    }}
                  >
                    {i18n.t(HomePageTKeys.startUsingButton)}
                  </Button>
                </Col>
              </Row>
            </Card>
          </div>
          <div className="slide-container">
            <Card>
              <Row gutter={[{ xs: 0, sm: 50 }, 0]}>
                <Col xs={24} md={12}>
                  <img src="/assets/t3.jpg" />
                </Col>
                <Col xs={24} md={12}>
                  <h2>{i18n.t(HomePageTKeys.oweOthers)}</h2>
                  <Button
                    type="link"
                    size="large"
                    href={appLink}
                    onClick={() => {
                      getAnalytics().track(Events.ClickGetStarted, {});
                      ReactGA.event({
                        category: "Clicked Get Started",
                        action: `Clicked Get Started`,
                      });
                    }}
                  >
                    {i18n.t(HomePageTKeys.startUsingButton)}
                  </Button>
                </Col>
              </Row>
            </Card>
          </div>
        </Carousel>
      </div>

      <div className="section get-started">
        <Row justify="space-between" align="middle">
          <Col xs={24} md={12} lg={12} xl={10}>
            <motion.div
              ref={getStartedRef2}
              animate={
                getStartedRef2InView
                  ? { x: "0", opacity: 1 }
                  : { x: "-100px", opacity: 0 }
              }
              transition={{ duration: 1 }}
            >
              <h1>{i18n.t(HomePageTKeys.downloadHeading)}</h1>
              <p>{i18n.t(HomePageTKeys.downloadPara)}</p>
              <GetStartedComponent defaultCountry={defaultCountry} />
            </motion.div>
          </Col>
          <Col xs={24} md={12} lg={12} xl={10}>
            <motion.img
              animate={
                getStartedRef2InView
                  ? { x: "0", opacity: 1 }
                  : { x: "50%", opacity: 0 }
              }
              transition={{ duration: 2 }}
              className="phone-mockup ipad"
              src="/assets/ipad.png"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HomePage;
