import { MobilePlatform } from "./user";

export enum LeadSource {
    WebApp = "web-app",
    Website = "Website",
    MobileApp = "mobile-app",
}

export enum LeadPlatform {
    ios = "ios",
    android = "android",
}

export interface Lead {
    phone?: string;
    email?: string,
    source: LeadSource;
    platform?: MobilePlatform,
    converted?: boolean,
    internal?: {
        providerId?: string,
        created_at: firebase.firestore.Timestamp;
    }
}