
import * as actions from './actions';
import { ActionType } from 'typesafe-actions';
import { ActionTypes } from './constants';
import { FirestoreDocument, User, Language, Currency } from '@hermes/schema';


export interface IState {
  currency: Currency;
  language?: Language;
  user: FirestoreDocument<User>;
}

export type Actions = ActionType<typeof actions>;

const initialState: IState = {
    currency: Currency.AED,
    user: undefined,
    language: Language.English,
}

export default function(state = initialState, action: Actions): IState {
    switch (action.type) {
        case ActionTypes.LOGIN:
            return {
                ...state,
                user: action.payload,
            }
        case ActionTypes.CLEAR_STATE:
            return initialState;
        case ActionTypes.UPDATE_USER:
            return {
                ...state,
                user: {
                    ...state.user,
                    doc: {
                        ...state.user.doc,
                        ...action.payload,
                    }
                }
            }
        case ActionTypes.CHANGE_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
    }
    return state;
}