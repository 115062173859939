import { QueryDocumentSnapshot, QuerySnapshot } from "./types";
import { FirestoreDocument, People, Collection } from "@hermes/schema";

export interface UserData{
    id: string,
    phone_number: string
}

export enum FileType {
    IMAGE_PNG = 'image/png',
    IMAGE_JPG = 'image/jpeg',
    PDF = 'application/pdf'
}

export interface Document {
    name?: string
    url: string
    type: FileType
    expiry_date?: firebase.firestore.Timestamp
}


 

export class PeopleQuery {
    
    constructor(private store: firebase.firestore.Firestore) {

    }

    getPeople(userId: string, peopleId: string) {
        return this.store
        .collection(Collection.User)
        .doc(userId)
        .collection(Collection.People)
        .doc(peopleId)
        .get()
        .then(snapshot => {
            const peopleDoc: FirestoreDocument<People> = {
                id: snapshot.id,
                doc: snapshot.data() as People,
            };
            return peopleDoc;
        });    
    };

    create(userId: string, person: People) {
        return this.store
            .collection("users")
            .doc(userId)
            .collection('people')
            .add(person)
    }

    checkPhoneNumber(userId: string, phone_number: string) {
        return this.store
            .collection(Collection.User)
            .doc(userId)
            .collection(Collection.People)
            .where("phone_number", "==", phone_number)
            .get() as Promise<QuerySnapshot<People>>
    }

    update(userId: string, person: FirestoreDocument<Partial<People>>) {
        return this.store
            .collection("users")
            .doc(userId)
            .collection('people')
            .doc(person.id)
            .update(person.doc);
    }

    get(userId: string) {
        return this.store
        .collection("users")
        .doc(userId)
        .collection('people')
        .get() as Promise<QuerySnapshot<People>>;
    }

    delete(userId: string, personId: string) {
        return this.store
            .collection("users")
            .doc(userId)
            .collection('people')
            .doc(personId)
            .delete();
    }

    getAllByCollectionGroup() {
        return this.store
            .collectionGroup(Collection.People)
            .get().then((doc) => {
                return doc.docs.map((d) => {
                    return {
                        id: d.id,
                        doc: d.data()
                    } as FirestoreDocument<People>;
                })
            });
    }
    

}